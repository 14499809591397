import { PriceKeys } from '@/entityes/invoice/invoice.type'
import { ref } from 'vue'
import { Ref } from 'vue'

export interface IGood {
  title: string
  description: string
  price: Ref<number>
  basePrice: Ref<number>
  name: string
  priceTag: Exclude<PriceKeys, 'add_services'>
  priceBaseTag: Exclude<PriceKeys, 'add_services'>
  inBasket: Ref<boolean>
  checked: Ref<boolean>
  showGood: boolean
  showResultFiles?: Ref<boolean>
}
export default function useGoodsDescription() {
  const goodsList: IGood[] = [
    {
      title: 'Декларация 3-НДФЛ',
      description:
        'Декларация в формате DC/XML и пояснительная записка с ' +
        'описанием всех расчётов для подачи онлайн на сайте ФНС',
      price: ref(0),
      basePrice: ref(0),
      name: 'Declaration',
      priceTag: 'declaration',
      priceBaseTag: 'base_declaration',
      inBasket: ref(true),
      checked: ref(true),
      showGood: true,
      showResultFiles: ref(false),
    },
    {
      title: 'Отчет о движении средств (ОДС)',
      description: 'В табличном формате для заполнения на сайте ФНС и/или в xml формате для загрузки на сайте ФНС',
      price: ref(0),
      basePrice: ref(0),
      name: 'Cfr',
      priceTag: 'cfr',
      priceBaseTag: 'base_cfr',
      inBasket: ref(true),
      checked: ref(true),
      showGood: true,
      showResultFiles: ref(false),
    },
    {
      title: 'Декларация 3-НДФЛ в pdf',
      description: 'Потребуется для подачи лично или по почте',
      price: ref(0),
      basePrice: ref(0),
      name: 'declaration_pdf',
      priceTag: 'declaration_pdf',
      priceBaseTag: 'base_declaration_pdf',
      inBasket: ref(false),
      checked: ref(false),
      showGood: true,
    },
    {
      title: 'Отчет о движении средств (ОДС) в pdf',
      description: 'Потребуется для подачи лично или по почте',
      price: ref(0),
      basePrice: ref(0),
      name: 'cfr_pdf',
      priceTag: 'cfr_pdf',
      priceBaseTag: 'base_cfr_pdf',
      inBasket: ref(false),
      checked: ref(false),
      showGood: true,
    },
    {
      title: 'Налоговое планирование',
      description:
        'Оставьте заявку, и мы расскажем вам, как оптимизировать налог по текущим и будущим сделкам с помощью нашего сервиса',
      price: ref(0),
      basePrice: ref(0),
      name: 'tax_plan',
      priceTag: 'tax_plan',
      priceBaseTag: 'tax_plan',
      inBasket: ref(false),
      checked: ref(false),
      showGood: true,
    },
    {
      title: 'Нал план',
      description: 'Не добавляй меня в корзину',
      price: ref(5000),
      basePrice: ref(0),
      name: 'TaxPlan',
      priceTag: 'TaxPlan',
      priceBaseTag: 'TaxPlan',
      inBasket: ref(false),
      checked: ref(false),
      showGood: false,
      showResultFiles: ref(false),
    },
  ]
  return {
    goodsList,
  }
}
