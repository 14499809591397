import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "mb-2" }
const _hoisted_3 = { class: "flex items-center mb-2" }
const _hoisted_4 = { class: "p-2" }
const _hoisted_5 = { class: "p-2" }
const _hoisted_6 = { key: 1 }

import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import useTextFilters from '@/plugins/TextFilters'
import { useBrokerName } from '@/use/useBrokerName'
import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'adminWarnings',
  setup(__props) {

const invoiceStore = useInvoiceStore()
const warnings = computed(() => !!invoiceStore.invoice && invoiceStore.invoice?.warning_and_error.warnings)
const { subStringFileName } = useTextFilters()
const { brokerNames } = useBrokerName()

// const showLog = ref(false)
// const toggleLog = () => {
//   showLog.value = !showLog.value
// }

return (_ctx: any,_cache: any) => {
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (Object.keys(warnings.value).length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(warnings.value, (accounts, broker) => {
            return (_openBlock(), _createElementBlock("div", { key: broker }, [
              _createElementVNode("h3", _hoisted_2, _toDisplayString(_unref(brokerNames)[broker]), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(accounts, (events, account) => {
                return (_openBlock(), _createElementBlock("div", { key: account }, [
                  _createElementVNode("div", _hoisted_3, [
                    _cache[0] || (_cache[0] = _createElementVNode("p", { class: "font-weight--extra mr-2" }, "имя файла:", -1)),
                    _createElementVNode("p", null, _toDisplayString(_unref(subStringFileName)(account, 30)), 1)
                  ]),
                  _createVNode(_component_el_collapse, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(events, (warnings, keys) => {
                        return (_openBlock(), _createBlock(_component_el_collapse_item, { key: keys }, {
                          title: _withCtx(() => [
                            _createElementVNode("h3", _hoisted_4, _toDisplayString(keys), 1)
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_5, _toDisplayString(warnings), 1)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 2
                  }, 1024)
                ]))
              }), 128))
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (Object.keys(warnings.value).length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, "Предупреждений не выявлено"))
      : _createCommentVNode("", true)
  ], 64))
}
}

})