import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '../../assets/delete_icon.svg'


const _hoisted_1 = { class: "mb-10 container-list" }
const _hoisted_2 = { class: "flex items-center mb-4" }
const _hoisted_3 = { class: "p-4 bg-gray min-h-[70px] flex items-center" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "mr-1" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "flex items-center" }
const _hoisted_9 = { class: "p-4 bg-gray min-h-[70px] flex items-center" }
const _hoisted_10 = { class: "flex items-center p-4" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "font-bold" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "flex items-center" }
const _hoisted_15 = { class: "sticky-basket" }
const _hoisted_16 = { class: "p-4 bg-gray flex items-center justify-between min-h-[70px]" }
const _hoisted_17 = { class: "p-4" }
const _hoisted_18 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_19 = { class: "pr-4" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { class: "flex items-center justify-between" }
const _hoisted_22 = { class: "flex items-center" }
const _hoisted_23 = ["src"]
const _hoisted_24 = { style: {"hyphens":"none"} }
const _hoisted_25 = { class: "flex items-center" }
const _hoisted_26 = { class: "font-weight--semi mr-4" }
const _hoisted_27 = ["onClick"]

import { useForm, useFormValues } from 'vee-validate'
import { computed } from 'vue'
import ShowOnlyLoadedFiles from '@/07-Shared/ui/ShowOnlyLoadedFiles.vue'
import { useRouter } from 'vue-router'
import OnBoardingDescriber from '@/describers/OnBoardingDescriber'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { ElNotification } from 'element-plus'
import { UploadFilled, Finished } from '@element-plus/icons-vue'
import AddIncomesRefundsDescriber from '@/describers/AddIncomesRefundDescriber'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import QuestionPopover from '@/07-Shared/ui/QuestionPopover.vue'
import FormItem from '@/07-Shared/ui/FormItem.vue'
import NgInvoiceVideo from '@/components/NgInvoiceVideo.vue'
import SplitWindow from '@/07-Shared/ui/SplitWindow.vue'
import useWindowWidth from '@/use/useWindowWidth'

export interface IProps {
  id: string | number
}

export default /*@__PURE__*/_defineComponent({
  __name: 'PreAddIncomesDeduction',
  props: {
    id: {}
  },
  setup(__props: any) {

const props = __props
const invoiceStore = useInvoiceStore()
const { createPreAddDeductions } = useInvoiceRepository()
const router = useRouter()
const { incomesDeductionsDescribers: describer, incomes, refunds } = AddIncomesRefundsDescriber()
const { preAddOnBoarding } = OnBoardingDescriber()
const { width } = useWindowWidth()
const hasErrors = computed(() => Object.keys(invoiceStore.invoice?.warning_and_error?.errors || {}).length !== 0)
const form = computed(() => {
  return describer
    .map((i: { tag: string }) => i.tag)
    .reduce((acc: { [key: string]: number }, tag: string) => {
      if (invoiceStore.invoice?.pre_selected_add_incomes) {
        Object.assign(acc, { [tag]: invoiceStore.invoice?.pre_selected_add_incomes[tag] || 0 })
      } else {
        Object.assign(acc, { [tag]: 0 })
      }
      return acc
    }, {})
})
useForm({
  initialValues: { ...form.value },
})
const formValues = useFormValues()
function deleteItemFromBasket(key: string) {
  formValues.value[key] = 0
}
const basketItems = computed(() => {
  return Object.keys(formValues.value).reduce((acc: any, key) => {
    if (formValues.value[key] > 0) {
      acc.push({
        value: formValues.value[key],
        tag: key,
        icon: describer.find((i: any) => i.tag === key)?.icon,
        name: describer.find((i: any) => i.tag === key)?.name,
      })
    }
    return acc
  }, <any>[])
})
function sendData() {
  const body = basketItems.value.reduce((acc: any, item: any) => {
    return Object.assign(acc, { [item?.tag]: item?.value })
  }, {})
  createPreAddDeductions(props.id, { pre_selected_add_incomes: body })
    .then(() => {
      router.push({ name: 'good_select' })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка cохранения',
        message: `Дополнительных доходов и вычетов ${err}`,
        type: 'error',
      })
    })
}

if (hasErrors.value) router.push({ name: 'file_in_invoice' })
useInvoiceRepository()
  .getInvoice(props.id)
  .then((res) => {
    useAsyncInvoiceReload().initAsync(res)
  })

return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_empty = _resolveComponent("el-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "mr-4" }, "Шаг 2. Выберите дополнительные доходы и вычеты", -1)),
      _createVNode(NgInvoiceVideo, {
        ref: "invoiceVideo",
        "show-button": true,
        "video-link": 'https://www.youtube.com/embed/8bocQ9Uhkik?si=U5G1gVBB4hn-xpIq'
      }, null, 512)
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("p", { class: "mb-2" }, "Мы внесём выбранные позиции в декларацию. 1 позиция +3500 руб.", -1)),
    _cache[6] || (_cache[6] = _createElementVNode("p", { class: "mb-4" }, " Или вы можете самостоятельно внести позиции в готовую декларацию после завершения заказа, в этом случае пропустите этот шаг. ", -1)),
    _createTextVNode(" " + _toDisplayString() + " ", 1),
    _createVNode(SplitWindow, null, {
      left: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h3", null, _toDisplayString(_unref(incomes).title), 1)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(incomes).items, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.tag,
              class: "flex items-center justify-between p-4"
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("img", {
                  src: item?.icon,
                  alt: "close_icon",
                  width: "20",
                  class: "mr-2 dark-purple-icon"
                }, null, 8, _hoisted_5),
                _createElementVNode("p", _hoisted_6, _toDisplayString(item.name), 1),
                (_unref(width) > 767)
                  ? (_openBlock(), _createBlock(QuestionPopover, {
                      key: 0,
                      color: "#021c1a4d",
                      width: 500
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          style: {"word-break":"break-word","hyphens":"none","text-align":"left"},
                          innerHTML: _unref(preAddOnBoarding)[item.tag]?.text
                        }, null, 8, _hoisted_7)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_8, [
                (_unref(formValues)[item.tag] > 0)
                  ? (_openBlock(), _createBlock(_component_el_icon, {
                      key: 0,
                      class: "dark:text-dark-green text-light-green mr-8",
                      size: "20"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Finished))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_el_tooltip, {
                  content: 'более одного нельзя',
                  trigger: "hover",
                  disabled: item?.max !== 1
                }, {
                  default: _withCtx(() => [
                    _createVNode(FormItem, {
                      name: item.tag,
                      label: ''
                    }, {
                      default: _withCtx(({ value, onBlur, onInput }) => [
                        _createVNode(_component_el_input_number, {
                          style: {"width":"104px !important"},
                          min: 0,
                          max: item.max || undefined,
                          "model-value": value,
                          type: "text",
                          name: item.tag,
                          onBlur: onBlur,
                          onInput: onInput
                        }, null, 8, ["max", "model-value", "name", "onBlur", "onInput"])
                      ]),
                      _: 2
                    }, 1032, ["name"])
                  ]),
                  _: 2
                }, 1032, ["disabled"])
              ])
            ]))
          }), 128)),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h3", null, _toDisplayString(_unref(refunds).title), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(refunds).items, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.key
              }, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("img", {
                    src: item?.icon,
                    alt: "close_icon",
                    width: "20",
                    class: "mr-2 dark-purple-icon"
                  }, null, 8, _hoisted_11),
                  _createElementVNode("p", _hoisted_12, _toDisplayString(item.title), 1)
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.refunds, (refund) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: refund.tag,
                    class: "flex items-center justify-between pl-11 py-4 pr-4"
                  }, [
                    _createElementVNode("span", null, [
                      _createTextVNode(_toDisplayString(refund.name) + " ", 1),
                      (_unref(width) > 767)
                        ? (_openBlock(), _createBlock(QuestionPopover, {
                            key: 0,
                            color: "#021c1a4d",
                            width: 500
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", {
                                style: {"word-break":"break-word","hyphens":"none","text-align":"left"},
                                innerHTML: _unref(preAddOnBoarding)[refund.tag]?.text
                              }, null, 8, _hoisted_13)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      (_unref(formValues)[refund.tag] > 0)
                        ? (_openBlock(), _createBlock(_component_el_icon, {
                            key: 0,
                            class: "dark:text-dark-green text-light-green mr-8",
                            size: "20"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(Finished))
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_el_tooltip, {
                        content: 'более одного нельзя',
                        trigger: "hover",
                        disabled: refund?.max !== 1
                      }, {
                        default: _withCtx(() => [
                          _createVNode(FormItem, {
                            name: refund.tag,
                            label: ''
                          }, {
                            default: _withCtx(({ value, onBlur, onInput }) => [
                              _createVNode(_component_el_input_number, {
                                style: {"width":"104px !important"},
                                min: 0,
                                max: refund.max || undefined,
                                "model-value": value,
                                type: "text",
                                name: refund.tag,
                                onBlur: onBlur,
                                onInput: onInput
                              }, null, 8, ["max", "model-value", "name", "onBlur", "onInput"])
                            ]),
                            _: 2
                          }, 1032, ["name"])
                        ]),
                        _: 2
                      }, 1032, ["disabled"])
                    ])
                  ]))
                }), 128))
              ]))
            }), 128))
          ])
        ])
      ]),
      right: _withCtx(() => [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Портфель", -1)),
            _createVNode(_component_el_button, {
              loading: _unref(createPreAddDeductions).indicator.value === true,
              type: "success",
              class: "button-main",
              onClick: sendData,
              icon: _unref(UploadFilled)
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" Рассчитать стоимость ")
              ])),
              _: 1
            }, 8, ["loading", "icon"])
          ]),
          _createElementVNode("div", _hoisted_17, [
            (_unref(invoiceStore).invoice)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _createVNode(ShowOnlyLoadedFiles, {
                    "broker-files": _unref(invoiceStore).invoice?.broker_files
                  }, null, 8, ["broker-files"])
                ]))
              : _createCommentVNode("", true),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "el-divider-purple mb-4" }, null, -1)),
            _cache[4] || (_cache[4] = _createElementVNode("h4", { class: "dark:text-dark-purple text-light-purple mb-4" }, "Дополнительные доходы и вычеты", -1)),
            _createElementVNode("div", _hoisted_19, [
              (basketItems.value.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(basketItems.value, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "mb-4"
                      }, [
                        _createElementVNode("div", _hoisted_21, [
                          _createElementVNode("div", _hoisted_22, [
                            _createElementVNode("img", {
                              class: "mr-2 dark-purple-icon",
                              src: item?.icon,
                              alt: "close_icon",
                              width: "20"
                            }, null, 8, _hoisted_23),
                            _createElementVNode("p", _hoisted_24, _toDisplayString(item.name), 1)
                          ]),
                          _createElementVNode("div", _hoisted_25, [
                            _createElementVNode("p", _hoisted_26, _toDisplayString(item.value), 1),
                            _createElementVNode("img", {
                              class: "button--text-hover delete-icon",
                              onClick: ($event: any) => (deleteItemFromBasket(item.tag)),
                              src: _imports_0,
                              alt: "close_icon",
                              width: "20"
                            }, null, 8, _hoisted_27)
                          ])
                        ])
                      ]))
                    }), 128))
                  ]))
                : (_openBlock(), _createBlock(_component_el_empty, {
                    key: 1,
                    "image-size": 44,
                    description: "Вы не добавили ни одного дохода или вычета"
                  }))
            ])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}
}

})