import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between mb-4" }
const _hoisted_2 = {
  key: 0,
  class: "font-weight--extra"
}
const _hoisted_3 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_4 = { class: "flex justify-between mb-1" }
const _hoisted_5 = { class: "mb-1 flex dark:text-dark-purple text-light-purple" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "flex justify-between items-center" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "font-weight--extra" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "flex justify-between mb-1" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "flex" }
const _hoisted_14 = { class: "mr-1" }
const _hoisted_15 = { class: "font-weight--extra" }

import { IGood } from '@/describers/GoodsDescriber'
import { computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { SetUp } from '@element-plus/icons-vue'
import LoaderDescriber from '@/06-Entities/File/model/LoaderDescriber'
import AddIncomesRefundsDescriber from '@/describers/AddIncomesRefundDescriber'
import useTextFilters from '@/plugins/TextFilters'

interface IProps {
  goods: IGood[]
  brokerFiles: any
  amountOperations: number | undefined
  addSelection: Record<string, Record<string, number>>
  personalPrice: number
  year: string | number
  tariff: string
}
interface IEmits {
  (e: 'invoice-total', value: number): void
}

export default /*@__PURE__*/_defineComponent({
  __name: 'Basket',
  props: {
    goods: {},
    brokerFiles: {},
    amountOperations: {},
    addSelection: {},
    personalPrice: {},
    year: {},
    tariff: {}
  },
  emits: ["invoice-total"],
  setup(__props: any, { emit: __emit }) {

const props = __props
const emit = __emit
const router = useRouter()
const { describers } = LoaderDescriber()
const { incomesDeductionsDescribers } = AddIncomesRefundsDescriber()
const { numberSeparator } = useTextFilters()
const tariffsRange = [[0], [1, 25], [26, 50], [51, 200], [201, 400], [401, 1000], [1001, 3000]]
const currentTariffRange = computed(() => {
  if (props.amountOperations) {
    for (const range of tariffsRange) {
      const [min, max] = range
      if (props.amountOperations >= min && props.amountOperations <= max) {
        return `(${range.join(' - ')} доходных операций)`
      }
    }
  } else {
    return `0 доходных операций)`
  }
})
const basket = computed(() => props.goods.filter((good) => good.inBasket.value))
const basketTotal = computed(() => {
  return props.goods.filter((good) => good.inBasket.value).reduce((acc, item) => acc + item.price.value, 0)
})
const addTotal = computed(() =>
  Object.keys(props.addSelection).reduce((acc, key) => acc + props.addSelection[key].price, 0)
)
const invoiceTotal = computed(
  () =>
    basketTotal.value +
    props.personalPrice +
    (basket.value.some((i) => i.name === 'Declaration') && props.tariff !== 'minimal' ? addTotal.value : 0)
)
const brokersRow = computed(() => {
  return props.brokerFiles.reduce((acc: string, fileTag: string, index: number) => {
    const desc = describers.find((i) => i.serverTag === fileTag)
    if (desc) {
      acc += `${desc.config.name}${index === props.brokerFiles.length - 1 ? '' : ', '}`
    }
    return acc
  }, '')
})

watch(
  invoiceTotal,
  (val: any) => {
    emit('invoice-total', val)
  },
  {
    immediate: true,
  }
)

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(basket.value, (good) => {
      return (_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h3", null, _toDisplayString(good.title) + " " + _toDisplayString(_ctx.year), 1),
          (['declaration_pdf', 'cfr_pdf'].includes(good.name))
            ? (_openBlock(), _createElementBlock("p", _hoisted_2, " ₽ " + _toDisplayString(_unref(numberSeparator)(good.price.value, ' ')), 1))
            : _createCommentVNode("", true)
        ]),
        (good.priceTag === 'declaration' || good.priceTag === 'cfr')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _cache[2] || (_cache[2] = _createElementVNode("h4", { class: "mr-1" }, "Брокерские отчеты", -1)),
                    (!!props.amountOperations && props.amountOperations > 0)
                      ? (_openBlock(), _createElementBlock("h4", _hoisted_6, _toDisplayString(currentTariffRange.value), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode(_component_el_tooltip, {
                    placement: "left",
                    content: "Изменить файлы брокеров"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        type: "primary",
                        icon: _unref(SetUp),
                        size: "small",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push({ name: 'file_in_invoice' }))),
                        link: ""
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode("Изменить")
                        ])),
                        _: 1
                      }, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", { innerHTML: brokersRow.value }, null, 8, _hoisted_8),
                _createElementVNode("p", _hoisted_9, "₽ " + _toDisplayString(_unref(numberSeparator)(good.price.value, ' ')), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (good.priceTag === 'declaration' && Object.keys(props.addSelection).length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _cache[5] || (_cache[5] = _createElementVNode("h4", { class: "dark:text-dark-purple text-light-purple" }, "Доп. доходы и вычеты", -1)),
                _createVNode(_component_el_tooltip, {
                  placement: "left",
                  content: "Изменить дополнительные доходы и вычеты"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      icon: _unref(SetUp),
                      size: "small",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push({ name: 'pre_add_deductions' }))),
                      link: ""
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode("Изменить")
                      ])),
                      _: 1
                    }, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              (_ctx.tariff === 'minimal' && Object.keys(props.addSelection).length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[6] || (_cache[6] = [
                    _createElementVNode("p", null, "самостоятельное внесение дополнительных доходов и вычетов", -1),
                    _createElementVNode("p", null, "в программе «Декларация» после завершения заказа.", -1)
                  ])))
                : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(props.addSelection, (value, addTag) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: addTag,
                      class: "flex justify-between mb-1"
                    }, [
                      _createElementVNode("div", null, [
                        _createElementVNode("p", null, _toDisplayString(_unref(incomesDeductionsDescribers).find((i) => i.tag === addTag)?.name), 1)
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("p", _hoisted_14, _toDisplayString(value?.qty) + " x", 1),
                        _createElementVNode("p", _hoisted_15, "₽ " + _toDisplayString(_unref(numberSeparator)(value?.price, ' ')), 1)
                      ])
                    ]))
                  }), 128))
            ]))
          : _createCommentVNode("", true),
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "el-divider-purple my-4" }, null, -1))
      ]))
    }), 256))
  ]))
}
}

})