import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import useInvoiceRemoteAdminRequest from '@/06-Entities/AdminInvoiceRemote/api/invoice_remote.admin.request'
import { ElNotification } from 'element-plus'
import { ref } from 'vue'
import { Refresh } from '@element-plus/icons-vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'ExanteRemote',
  setup(__props) {

const { changeExanteStatus } = useInvoiceRemoteAdminRequest()
const invoiceStore = useInvoiceStore()
const isLoading = ref(false)
function change() {
  isLoading.value = true
  changeExanteStatus(invoiceStore.invoice.id)
    .then(() => {
      ElNotification({
        title: 'Успешно',
        message: `отправлен запрос на изменения статуса`,
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `запроса на изменение статуса. ${err}`,
        type: 'error',
      })
    })
    .finally(() => (isLoading.value = false))
}

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[1] || (_cache[1] = _createElementVNode("h4", { class: "font-weight--extra" }, "Нажмите, чтобы изменить статус на files_generated", -1)),
    _createVNode(_component_el_tooltip, {
      class: "box-item",
      effect: "dark",
      content: "Изменить статус заказа",
      placement: "top"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          size: "small",
          loading: isLoading.value,
          type: "primary",
          onClick: change,
          disabled: isLoading.value,
          icon: _unref(Refresh)
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Изменить статус заказа ")
          ])),
          _: 1
        }, 8, ["loading", "disabled", "icon"])
      ]),
      _: 1
    })
  ], 64))
}
}

})