import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "flex items-center" }

import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import InvoiceInfo from '@/06-Entities/AdminInvoiceRemote/ui/InvoiceInfo.vue'
import FileInInvoiceTab from '@/06-Entities/AdminInvoiceRemote/ui/FileInInvoiceTab.vue'
import AdminErrors from '@/06-Entities/AdminInvoiceRemote/ui/AdminErrors.vue'
import AdminWarnings from '@/06-Entities/AdminInvoiceRemote/ui/adminWarnings.vue'
import AdminRemote from '@/06-Entities/AdminInvoiceRemote/ui/AdminRemote.vue'
import { computed, ref } from 'vue'
import { WarningFilled } from '@element-plus/icons-vue'
import ExanteRemote from '@/06-Entities/AdminInvoiceRemote/ui/ExanteRemote.vue'
import TBankRemote from '@/06-Entities/AdminInvoiceRemote/ui/TBankRemote.vue'
import TBankChatFiles from '@/06-Entities/AdminInvoiceRemote/ui/TBankChatFiles.vue'
import TBankKassa from '@/06-Entities/AdminInvoiceRemote/ui/TBankKassa.vue'
import TBankChat from '@/06-Entities/AdminInvoiceRemote/ui/TBankChat.vue'
import useColors from '@/07-Shared/model/useColors'


export default /*@__PURE__*/_defineComponent({
  __name: 'AdminInvoiceRemote',
  setup(__props) {

const invoiceStore = useInvoiceStore()
const hasErrors = computed(
  () => !!invoiceStore.invoice && Object.keys(invoiceStore.invoice?.warning_and_error.errors).length > 0
)
const hasWarnings = computed(
  () => !!invoiceStore.invoice && Object.keys(invoiceStore.invoice?.warning_and_error.warnings).length > 0
)
// const showWarnings = {
//   interactive_brokers_files: ['Shorts not in MTM'],
// }
const hasMsg = ref(false)
function setNotification(val: boolean) {
  hasMsg.value = val
}
const exanteInvoice = computed(() => invoiceStore.invoice?.email === 'exante@exante.exante')
const tinkoffInvoice = computed(() => invoiceStore.invoice?.email === 'tinkoff@tinkoff.tinkoff')
const tinkoffPremiumInvoice = computed(() => invoiceStore.invoice?.email === 'external_premium@tinkoff.tinkoff')
const { colors, isDark } = useColors()

return (_ctx: any,_cache: any) => {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createBlock(_component_el_tabs, { class: "bg-gray p-4 select" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_tab_pane, { label: "Инфо по заказу" }, {
        default: _withCtx(() => [
          _createVNode(InvoiceInfo)
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, { label: "Файлы заказа" }, {
        default: _withCtx(() => [
          _createVNode(FileInInvoiceTab)
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, null, {
        label: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (hasErrors.value)
              ? (_openBlock(), _createBlock(_component_el_icon, {
                  key: 0,
                  color: _unref(isDark) ? _unref(colors).dark.ng_red : _unref(colors).light.ng_error,
                  class: "mr-1"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(WarningFilled))
                  ]),
                  _: 1
                }, 8, ["color"]))
              : _createCommentVNode("", true),
            _cache[0] || (_cache[0] = _createElementVNode("p", null, "Ошибки", -1))
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(AdminErrors)
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, null, {
        label: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            (hasWarnings.value)
              ? (_openBlock(), _createBlock(_component_el_icon, {
                  key: 0,
                  color: _unref(isDark) ? _unref(colors).dark.ng_orange : _unref(colors).light.ng_orange,
                  class: "mr-1"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(WarningFilled))
                  ]),
                  _: 1
                }, 8, ["color"]))
              : _createCommentVNode("", true),
            _cache[1] || (_cache[1] = _createElementVNode("p", null, "Предупреждения", -1))
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(AdminWarnings)
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, { label: "Управление заказом" }, {
        default: _withCtx(() => [
          _createVNode(AdminRemote)
        ]),
        _: 1
      }),
      (exanteInvoice.value)
        ? (_openBlock(), _createBlock(_component_el_tab_pane, {
            key: 0,
            label: "Изменить статус заказа Exante"
          }, {
            default: _withCtx(() => [
              _createVNode(ExanteRemote)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (tinkoffInvoice.value || tinkoffPremiumInvoice.value)
        ? (_openBlock(), _createBlock(_component_el_tab_pane, {
            key: 1,
            label: "Управление T-Bank"
          }, {
            default: _withCtx(() => [
              _createVNode(TBankRemote)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (tinkoffPremiumInvoice.value)
        ? (_openBlock(), _createBlock(_component_el_tab_pane, {
            key: 2,
            label: "Чат T-Bank"
          }, {
            label: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                (hasMsg.value)
                  ? (_openBlock(), _createBlock(_component_el_icon, {
                      key: 0,
                      color: _unref(isDark) ? _unref(colors).dark.ng_orange : _unref(colors).light.ng_orange,
                      class: "mr-1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(WarningFilled))
                      ]),
                      _: 1
                    }, 8, ["color"]))
                  : _createCommentVNode("", true),
                _cache[2] || (_cache[2] = _createElementVNode("p", null, "Чат T-Bank", -1))
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(TBankChat, { onNew: setNotification })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (tinkoffPremiumInvoice.value)
        ? (_openBlock(), _createBlock(_component_el_tab_pane, {
            key: 3,
            label: "Файлы чата T-Bank"
          }, {
            default: _withCtx(() => [
              _createVNode(TBankChatFiles)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (tinkoffPremiumInvoice.value)
        ? (_openBlock(), _createBlock(_component_el_tab_pane, {
            key: 4,
            label: "Создать оплату T-Bank"
          }, {
            default: _withCtx(() => [
              _createVNode(TBankKassa)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})