<script setup lang="ts">
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import useInvoiceRemoteAdminRequest from '@/06-Entities/AdminInvoiceRemote/api/invoice_remote.admin.request'
import { ElNotification } from 'element-plus'
import { ref } from 'vue'
import { Refresh } from '@element-plus/icons-vue'
const { changeExanteStatus } = useInvoiceRemoteAdminRequest()
const invoiceStore = useInvoiceStore()
const isLoading = ref(false)
function change() {
  isLoading.value = true
  changeExanteStatus(invoiceStore.invoice.id)
    .then(() => {
      ElNotification({
        title: 'Успешно',
        message: `отправлен запрос на изменения статуса`,
        type: 'success',
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `запроса на изменение статуса. ${err}`,
        type: 'error',
      })
    })
    .finally(() => (isLoading.value = false))
}
</script>

<template>
  <h4 class="font-weight--extra">Нажмите, чтобы изменить статус на files_generated</h4>
  <el-tooltip class="box-item" effect="dark" content="Изменить статус заказа" placement="top">
    <el-button size="small" :loading="isLoading" type="primary" @click="change" :disabled="isLoading" :icon="Refresh">
      Изменить статус заказа
    </el-button>
  </el-tooltip>
</template>

<style scoped lang="sass"></style>
