import { useRequestService } from '@/use/useRequestService'
import { IInvoice, IInvoiceList } from './invoice.type'
import { useInvoiceStore } from './invoice.store'
import { FetchError } from 'ofetch'
import { NotFoundException } from '@/errors/FetchErrors'
import { ref } from 'vue'
import { IProfile, IProfilesList } from '@/entityes/profiles/profiles.type'
import { IPaymentBody } from './invoice.type'

export function useInvoiceRepository() {
  const { fetch } = useRequestService()
  const invoiceStore = useInvoiceStore()

  const getInvoiceList = async (page: number | string = 1): Promise<IInvoiceList> => {
    getInvoiceList.indicator.value = true
    return new Promise((resolve, reject) => {
      fetch<IInvoiceList>(`/invoices?page=${page}`)
        .then((res) => {
          invoiceStore.invoices = res
          resolve(res)
        })
        .catch((err: FetchError) => {
          reject(err)
        })
        .finally(() => {
          getInvoiceList.indicator.value = false
        })
    })
  }
  getInvoiceList.indicator = ref(false)
  const createInvoice = () => {
    return fetch<IInvoice>('/invoices', {
      method: 'post',
    })
  }
  function getInvoice(id: number | string | string[]): Promise<IInvoice> {
    return new Promise((resolve, reject) => {
      getInvoice.indicator.value = true
      fetch<IInvoice>(`/invoices/${id}`)
        .then((res) => {
          invoiceStore.invoice = res
          resolve(res)
        })
        .catch((err: FetchError) => {
          reject(err)
        })
        .finally(() => {
          getInvoice.indicator.value = false
        })
    })
  }
  getInvoice.indicator = ref<boolean>(false)
  async function getInvoiceByUid(uid: number | string): Promise<number | string> {
    return fetch<IInvoiceList>(`/admin/invoices?q[external_id_eq]=${uid}`).then((res) => {
      if (res.invoices.length === 1) {
        return res.invoices[0].id
      } else {
        throw new NotFoundException()
      }
    })
  }
  async function getInvoiceListUserShowForAdmin(
    page: number | string = 1,
    profiles: IProfilesList | any
  ): Promise<IInvoiceList> {
    getInvoiceListUserShowForAdmin.indicator.value = true
    const query = profiles.map((i: IProfile) => `q[profile_id_in][]=${i.id}`).join('&')
    return new Promise((resolve, reject) => {
      fetch<IInvoiceList>(`/admin/invoices?${query}&q[s]=id desc&page=${page}`)
        .then((res) => {
          invoiceStore.userShowInvoices = res
          resolve(res)
        })
        .catch((err: FetchError) => {
          console.log(err)
          reject(err)
        })
        .finally(() => {
          getInvoiceListUserShowForAdmin.indicator.value = false
        })
    })
  }
  getInvoiceListUserShowForAdmin.indicator = ref(false)
  async function createPreAddDeductions(
    id: string | number,
    body: { pre_selected_add_incomes: { [key: string]: number } }
  ) {
    createPreAddDeductions.indicator.value = true
    return fetch<IInvoice>(`/invoices/${id}/pre_selection_add_incomes`, {
      method: 'post',
      body,
    })
      .then(() => {
        getInvoice(id)
      })
      .finally(() => {
        createPreAddDeductions.indicator.value = false
      })
  }
  createPreAddDeductions.indicator = ref(false)
  function goodSelect(id: string | number, body: IPaymentBody) {
    return fetch<{ [key: string]: string }>(`/invoices/${id}/goods_selection`, {
      method: 'post',
      body,
    })
  }
  function addPromocode(id: string | number, body: Record<string, string>) {
    return fetch<IInvoice>(`/invoices/${id}/add_promocode`, {
      method: 'patch',
      body,
    })
  }
  function skipPayment(id: string | number) {
    return fetch(`/invoices/${id}/payment_admin`)
  }
  function surchargePayment(id: string | number) {
    return fetch<Record<string, string>>(`/invoices/${id}/payment_surcharge`, {
      method: 'post',
    })
  }
  function dropDiscountRequest(id: string | number, body: Record<string, boolean>) {
    return fetch(`/invoices/${id}/set_discount_request`, {
      method: 'patch',
      body,
    })
  }
  function sendDiscountRequest(body: Record<string, string | number | boolean>) {
    return fetch(`https://b24.ndfl.guru/rest/6/hnxkl6czz0lk0570/finoscope.discountRequest`, {
      method: 'post',
      body,
    })
  }
  function dropThankUPage(invoiceId: string | number, body: any) {
    return new Promise((resolve, reject) => {
      fetch(`/invoices/${invoiceId}/drop_thank_you_page`, {
        method: 'patch',
        body,
      })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  return {
    getInvoiceList,
    getInvoice,
    createInvoice,
    getInvoiceByUid,
    getInvoiceListUserShowForAdmin,
    createPreAddDeductions,
    goodSelect,
    addPromocode,
    skipPayment,
    surchargePayment,
    dropDiscountRequest,
    sendDiscountRequest,
    dropThankUPage,
  }
}
